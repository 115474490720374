<template>
  <div>
    <table v-if="ingredients && ingredients.length > 0" class="table">
      <thead>
        <tr>
          <th v-if="display.includes('label')">Intitulé</th>
          <th v-if="display.includes('product_code')" class="text-center">
            Code
          </th>
          <th v-if="display.includes('suplier')" class="text-center">
            Fournisseur
          </th>
          <th v-if="display.includes('price')" class="text-right">
            Prix ingrédient
          </th>
          <th v-if="display.includes('quantity')" class="text-center">
            Portions
          </th>
          <th
            v-if="display.includes('quantity_for_quantity')"
            class="text-right"
            style="min-width: 130px"
          >
            Qt.<span v-if="quantity > 0">/ {{ quantity }} Plats</span>
          </th>
          <th
            v-if="display.includes('cost_for_quantity')"
            class="text-right"
            style="min-width: 130px"
          >
            Coût<span v-if="quantity > 0">/ {{ quantity }} Plats</span>
          </th>
        </tr>
      </thead>
      <template v-for="ingredient in ingredients">
        <tbody :key="ingredient.recipe_ingredient_id">
          <tr>
            <td v-if="display.includes('label')">
              <b>{{ ingredient.label }}</b>
              <p v-if="ingredient.description">
                {{ ingredient.description }}
              </p>
            </td>
            <td v-if="display.includes('product_code')" class="text-center">
              {{ ingredient.product_code }}
            </td>
            <td v-if="display.includes('suplier')" class="text-center">
              {{ ingredient.suplier || '-' }}
            </td>
            <td v-if="display.includes('price')" class="text-right">
              {{ ingredient.price | currency }}&nbsp;HT
            </td>

            <td v-if="display.includes('quantity')" class="text-center">
              {{ ingredient.quantity }}
            </td>

            <td
              v-if="display.includes('quantity_for_quantity')"
              class="text-right"
            >
              {{
                ingredient.quantity_for_quantity
                  | currency({ fractionCount: 4, symbol: '' })
              }}
              {{ ingredient.unit }}
            </td>
            <td v-if="display.includes('cost_for_quantity')" class="text-right">
              {{
                ingredient.cost_for_quantity | currency({ fractionCount: 3 })
              }}
              HT
            </td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  name: 'RecipeIngredientsCard',
  props: {
    quantity: {
      type: Number,
      default() {
        return 0
      },
    },
    ingredients: {
      type: Array,
      default() {
        return []
      },
    },
    display: {
      type: Array,
      default() {
        return [
          'label',
          'product_code',
          'price',
          'quantity_for_quantity',
          'cost_for_quantity',
        ]
      },
    },
  },
}
</script>
