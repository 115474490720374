var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(_vm.task.id)?_c('b-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h3',[_vm._v("Tâche / "+_vm._s(_vm.task.recipe.label))])])]),_c('b-tabs',{attrs:{"content-class":"mt-2"}},[_c('b-tab',{attrs:{"title":"Propriétés","active":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-11"},[(_vm.displayTaskForm === false)?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("#")]),_c('dd',{staticClass:"col-sm-9"},[_c('b',[_c('copy-value',{attrs:{"value":_vm.task.id}})],1)]),_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("Statut")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.task.production_batch_id)?_c('router-link',{attrs:{"to":{
                      name: 'productionBatch',
                      params: { id: _vm.task.production_batch_id },
                    }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(("PRODUCTION." + (_vm.task.status))))+" ")])]):_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(("PRODUCTION." + (_vm.task.status))))+" ")])],1),_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("Source")]),_c('dd',{staticClass:"col-sm-9"},[_c('b',[_vm._v(_vm._s(_vm.$t(("PRODUCTION." + (_vm.task.source)))))])]),_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("Recette")]),_c('dd',{staticClass:"col-sm-9"},[_c('router-link',{attrs:{"to":("/recipes/" + (_vm.task.recipe_id))}},[_c('b',[_vm._v(" "+_vm._s(_vm.task.recipe.label)+" ")])])],1),_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("Livraison")]),_c('dd',{staticClass:"col-sm-9"},[_c('b',[_vm._v(_vm._s(_vm.$dayjs(_vm.task.due_at).fromNow()))])]),_c('dt',{staticClass:"col-sm-3 text-right"},[_vm._v("Quantité")]),_c('dd',{staticClass:"col-sm-9"},[_c('b',[_vm._v(_vm._s(_vm.task.quantity))])])]):_c('div',{staticClass:"m-lg-5 mb-5"},[_c('h3',[_vm._v("Modifier la tâche")]),_c('production-task-form',{attrs:{"production-task-id":_vm.task.id,"display":_vm.displayTaskForm,"week":{
                    start_at: _vm.$dayjs(_vm.task.due_at)
                      .startOf('week')
                      .format('YYYY-MM-DD'),
                    end_at: _vm.$dayjs(_vm.task.due_at)
                      .endOf('week')
                      .format('YYYY-MM-DD'),
                  }},on:{"update:display":function($event){_vm.displayTaskForm=$event},"updated":_vm.init,"deleted":function($event){return _vm.$router.push('/productions')}}})],1)]),_c('div',{staticClass:"col-lg-1"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();_vm.displayTaskForm = !_vm.displayTaskForm}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)])])]),_c('b-tab',{attrs:{"title":"Métadonnées"}},[_c('pre',[_vm._v(_vm._s(_vm.task))])])],1)],1):_vm._e()],1),_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(_vm.task.id)?_c('b-card',[_c('recipe-ingredients-card',{attrs:{"quantity":_vm.task.quantity,"ingredients":_vm.task.attributes.ingredients}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }