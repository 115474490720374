<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <b-form-group label="Type">
          <b-form-radio-group
            v-model="form.source"
            :options="[
              { text: 'Rutabago', value: 'INTERNAL' },
              { text: 'La Chapelle', value: 'LACHAPELLE' },
              { text: 'Portage de repas', value: 'MEAL_DELIVERY' },
            ]"
            name="source"
          />
        </b-form-group>
        <b-form-group label-for="input-due_at">
          <validation-provider
            #default="{ errors }"
            name="Fabrication pour le"
            rules="required"
          >
            <b-form-datepicker
              v-model="form.due_at"
              placeholder="Fabrication le"
              :initial-date="week.start_at"
              :date-disabled-fn="dateDisabled"
              locale="fr"
              start-weekday="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group v-if="creation == true" label-for="input-recipe_id">
          <vue-typeahead-bootstrap
            ref="typehead"
            v-model="query"
            :data="recipes"
            :serializer="(s) => s.label"
            placeholder="Chercher une recette"
            @hit="selectRecipe($event)"
          />
        </b-form-group>
        <b-form-group label-for="input-quantity">
          <b-form-input
            v-model="form.quantity"
            type="number"
            placeholder="Quantité à fabriquer"
          />
        </b-form-group>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="productionTaskId"
              v-b-modal.modal-delete
              variant="danger"
            >
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer la sélection"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteItem()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ query }} </pre> -->
    <!-- <pre>{{ form }} </pre> -->
    <!-- <pre>{{ week }} </pre> -->
    <!-- <pre>{{ recipes }} </pre> -->
  </div>
</template>

<script>
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BFormRadioGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'ProductionTaskForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BButton,
    BModal,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    VueTypeaheadBootstrap,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    productionTaskId: {
      default: null,
      type: String,
    },
    week: {
      type: Object,
      default() {
        return {}
      },
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      period: null,
      query: null,
      recipes: [],
      form: {
        source: 'INTERNAL',
        due_at: null,
        recipe_id: null,
        quantity: 1,
      },
      constants: {},
    }
  },
  watch: {
    query(newQuery) {
      this.searchDebounce(newQuery, this.searchRecipes)
    },
    productionTaskId() {
      this.init()
    },
    period(value) {
      if (value && value.start_at) {
        this.form.start_at = value.start_at
        this.form.end_at = value.end_at
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'WEEKS' },
      })
      this.constants = respConstants.data

      if (this.productionTaskId) {
        const resp = await this.$http.get(
          `/productions/tasks/${this.productionTaskId}/`,
          {
            headers: {
              'X-Fields': `${Object.keys(this.form).join(
                ','
              )},seasons,categories,dishes,schemes`,
            },
          }
        )
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.productionTaskId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/productions/tasks/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/productions/tasks/${this.productionTaskId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async deleteItem() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/productions/tasks/${this.productionTaskId}/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
    dateDisabled(ymd) {
      return !this.$dayjs(ymd).isBetween(
        this.week.start_at,
        this.week.end_at,
        null,
        '[]'
      )
    },
    searchDebounce: _.debounce((term, search) => {
      search(term)
    }, 500),
    async searchRecipes(term) {
      const resp = await this.$http.get('/recipes/', {
        params: { like_term: term, per_page: 100 },
        headers: {
          'X-Fields': 'results{id,label}',
        },
      })
      this.recipes = resp.data.results
    },
    resetRecipe() {
      this.form.recipe_id = null
      this.query = ''
    },
    selectRecipe(recipe) {
      this.form.recipe_id = recipe.id
    },
  },
}
</script>
