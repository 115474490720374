<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="task.id">
        <div class="row">
          <div class="col-lg-12">
            <h3>Tâche / {{ task.recipe.label }}</h3>
          </div>
        </div>
        <b-tabs content-class="mt-2">
          <b-tab title="Propriétés" active>
            <div class="row">
              <div class="col-lg-11">
                <dl v-if="displayTaskForm === false" class="row">
                  <dt class="col-sm-3 text-right">#</dt>
                  <dd class="col-sm-9">
                    <b><copy-value :value="task.id" /></b>
                  </dd>
                  <dt class="col-sm-3 text-right">Statut</dt>
                  <dd class="col-sm-9">
                    <router-link
                      v-if="task.production_batch_id"
                      :to="{
                        name: 'productionBatch',
                        params: { id: task.production_batch_id },
                      }"
                    >
                      <span class="text-nowrap">
                        {{ $t(`PRODUCTION.${task.status}`) }}
                      </span>
                    </router-link>
                    <span v-else class="text-nowrap">
                      {{ $t(`PRODUCTION.${task.status}`) }}
                    </span>
                  </dd>
                  <dt class="col-sm-3 text-right">Source</dt>
                  <dd class="col-sm-9">
                    <b>{{ $t(`PRODUCTION.${task.source}`) }}</b>
                  </dd>
                  <dt class="col-sm-3 text-right">Recette</dt>
                  <dd class="col-sm-9">
                    <router-link :to="`/recipes/${task.recipe_id}`">
                      <b>
                        {{ task.recipe.label }}
                      </b>
                    </router-link>
                  </dd>
                  <dt class="col-sm-3 text-right">Livraison</dt>
                  <dd class="col-sm-9">
                    <b>{{ $dayjs(task.due_at).fromNow() }}</b>
                  </dd>
                  <dt class="col-sm-3 text-right">Quantité</dt>
                  <dd class="col-sm-9">
                    <b>{{ task.quantity }}</b>
                  </dd>
                </dl>
                <div v-else class="m-lg-5 mb-5">
                  <h3>Modifier la tâche</h3>
                  <production-task-form
                    :production-task-id="task.id"
                    :display.sync="displayTaskForm"
                    :week="{
                      start_at: $dayjs(task.due_at)
                        .startOf('week')
                        .format('YYYY-MM-DD'),
                      end_at: $dayjs(task.due_at)
                        .endOf('week')
                        .format('YYYY-MM-DD'),
                    }"
                    @updated="init"
                    @deleted="$router.push('/productions')"
                  />
                </div>
              </div>
              <div class="col-lg-1">
                <button
                  class="btn btn-primary btn-sm"
                  @click.prevent="displayTaskForm = !displayTaskForm"
                >
                  <feather-icon icon="EditIcon" />
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab title="Métadonnées">
            <pre>{{ task }}</pre>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="task.id">
        <recipe-ingredients-card
          :quantity="task.quantity"
          :ingredients="task.attributes.ingredients"
        />
      </b-card>
    </b-overlay>
    <!-- <pre>{{ task }}</pre> -->
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BOverlay } from 'bootstrap-vue'
import RecipeIngredientsCard from '@/components/RecipeIngredientsCard.vue'
import ProductionTaskForm from '@/components/ProductionTaskForm.vue'

const productionFields = '*,recipe{label}'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BOverlay,
    RecipeIngredientsCard,
    ProductionTaskForm,
  },
  data() {
    return {
      loading: false,
      displayTaskForm: false,
      task: {},
      recipes: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 8,
        term: null,
      },
    }
  },
  computed: {},
  async created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const resp = await this.$http.get(
        `/productions/tasks/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields': productionFields,
          },
        }
      )
      this.task = resp.data
      this.loading = false
    },
  },
}
</script>
<style scoped>
.card-body {
  padding: 1rem !important;
}
</style>
